import { generatePath, Redirect, Route } from 'react-router';
import { IRoute } from '../interfaces/iroute.interface';
import { HildaStatus } from '../interfaces/hildaStatus.enum';
import { MenuIndex } from '../../components/menu/menu.enum';
import AccountsLoad from '../../views/accountsLoad/accountsLoad';
import AdvisorDashboard from '../../views/advisorDashboard/advisorDashboard';
import HildaDetails from '../../views/hildaDetails/hildaDetails';
import HildaAwaitingClientApproval from '../../views/hildaAwaitingClientApproval/hildaAwaitingClientApproval';
import HildaPendingClient from '../../views/hildaPendingClient/hildaPendingClient';
import AdvisorPreFill from '../../views/advisorPreFill/advisorPreFill';
import HildaPendingAdvisor from '../../views/hildaPendingAdvisor/hildaPendingAdvisor';
import ClientConfig from '../../views/clientConfig/clientConfig';
import { Roles } from '../interfaces/roles.enum';
import { IUserData } from '../context/userContext';
import AccountUploadIcon from '../icons/accountUploadIcon';
import LinkIcon from '../icons/linkIcon';
import CoinIcon from '../icons/coinIcon';
import Assets from '../../views/assets/assets';
import FileIcon from '../icons/fileIcon';
import MarketingMaterials from '../../views/marketingMaterials/marketingMaterials';
import PlaidView from '../../views/plaidView';
import Landing from '../../views/landing/landing';
import { Profile } from '../../views/profile/profile';

export enum RouteName {
  Overview = 'Overview',
  Accounts = 'Accounts',
  AccountsLoad = 'Accounts Load',
  Home = 'Home',
  HildaDetails = 'Hilda Details',
  AdvisorPreFill = 'Advisor PreFill',
  ClientConfig = 'Client Config',
  Assets = 'Assets',
  MarketingMaterials = 'Marketing Materials',
  PlaidView = 'Plaid View',
  Profile = 'Profile'
}

const getAccountUploadIcon = (className: any) => (
  <AccountUploadIcon alt="account upload" className={className} />
);

const getLinkIcon = (className: any) => (
  <LinkIcon alt="link icon" className={className} />
);

const getCoinIcon = (className: any) => (
  <CoinIcon alt="coin" className={className} />
);

const getFileIcon = (className: any) => (
  <FileIcon alt="file" className={className} />
);

export const getRoutes = (menu: number): IRoute[] => {
  switch (menu) {
    case MenuIndex.TopNav: {
      return [
        {
          id: 'a',
          componentName: 'Overview',
          name: 'Overview',
          path: '/overview',
          render: () => {
            return (
              <Redirect
                to={{
                  pathname: `/`,
                }}
              />
            );
          },
          permission: 'overview',
        },
        {
          id: 'b',
          componentName: 'Accounts',
          name: 'Accounts',
          path: '/accounts/:advisorFirmId',
          render: (_props, { userData }) => {
            const user = userData.user;
            const routes = getAllRoutes();
            const hildaDetails = routes.find(
              (route) => route.componentName === 'HildaDetails'
            );
            const advisorDashboard = routes.find(
              (route) => route.componentName === 'AdvisorDashboard'
            );
            const hilda = getHilda(userData);
            const hildaDetailsPath = generatePath(hildaDetails?.path || '', {
              advisorFirmId: user?.advisorFirmId,
              hildaAccountId: hilda?.id || '0',
              hildaStatusId: hilda?.statusId || '0',
              hildaSubmitted: hilda?.submitted || false,
            });
            const advisorDashboardPath = generatePath(
              advisorDashboard?.path || '',
              {
                advisorFirmId: user?.advisorFirmId,
              }
            );

            if (user?.role.name === Roles.HildaUser) {
              return <Redirect to={hildaDetailsPath} />;
            } else {
              return <Redirect to={advisorDashboardPath} />;
            }
          },
          permission: '',
        },
      ];
    }
    case MenuIndex.Overview: {
      return [
        {
          id: 'a',
          componentName: 'Assets',
          name: 'Assets',
          path: '/overview/:advisorFirmId/assets',
          render: () => {
            return <Route component={Assets} />;
          },
          permission: 'assets',
          getIcon: getCoinIcon,
        },
        {
          id: 'b',
          componentName: 'MarketingMaterials',
          name: 'Marketing Materials',
          path: '/overview/:advisorFirmId/marketingMaterials',
          render: () => {
            return <Route component={MarketingMaterials} />;
          },
          permission: 'marketingMaterials',
          getIcon: getFileIcon,
        },
        {
          id: 'c',
          componentName: 'AccountsLoad',
          name: 'Accounts Load',
          path: '/overview/:advisorFirmId/accountUpLoad',
          render: () => {
            return <Route component={AccountsLoad} />;
          },
          permission: 'accountUpload',
          getIcon: getAccountUploadIcon,
        },
        {
          id: 'd',
          componentName: 'ClientConfig',
          name: 'Client Config',
          path: '/overview/:advisorFirmId/clientConfig',
          render: () => {
            return <Route component={ClientConfig} />;
          },
          permission: 'clientConfig',
          getIcon: getLinkIcon,
        },
      ];
    }
    case MenuIndex.Accounts: {
      return [
        {
          id: "a",
          componentName: "Profile",
          name: "Profile",
          path: "/profile",
          render: () => <Route component={Profile} />,
          permission: "profile",
        },
      ];
    }
    case MenuIndex.UserOptions: {
      return [];
    }
    case MenuIndex.HildaDetails: {
      return [];
    }
    default: {
      return [
        {
          id: 'a',
          componentName: 'Home',
          name: 'Home',
          path: '/',
          render: (_props, { userData, setUserData }) => {
            if (userData.authState === 'signedOut') {
              return <Route component={Landing} />;
            }
            const routes = getAllRoutes();
            const advisorFirmId = userData.user.advisorFirmId;
            const hilda = getHilda(userData);
            const redirectPath = generatePath(userData.reRoute, {
              advisorFirmId: userData.user.advisorFirmId,
              hildaAccountId: hilda?.id,
              hildaStatusId: hilda?.statusId,
              hildaSubmitted: hilda?.submitted || false,
            });
            const hildaDetails = routes.find(
              (route) => route.componentName === 'HildaDetails'
            );
            const hildaDetailsPath = generatePath(hildaDetails?.path || '', {
              advisorFirmId,
              hildaAccountId: hilda?.id || '0',
              hildaStatusId: hilda?.statusId || '0',
              hildaSubmitted: hilda?.submitted || false,
            });

            if (userData.user.role.name === 'HildaUser') {
              return <Redirect to={hildaDetailsPath} />;
            } else if (userData.user.role.name === 'AdvisorRep')
              return (
                <Redirect
                  to={{
                    pathname: `/overview/${advisorFirmId}/marketingMaterials`,
                  }}
                />
              );
            else if (
              userData.user.role.name === 'SupportAdmin' ||
              userData.user.role.name === 'AdvisorAdmin'
            )
              return (
                <Redirect
                  to={{
                    pathname: `/overview/${advisorFirmId}/assets`,
                  }}
                />
              );
            else {
              setUserData({ ...userData, reRoute: '/' });
              return <Redirect to={{ pathname: redirectPath }} />;
            }
          },
          permission: '',
        },
        {
          id: 'a',
          componentName: 'HildaDetails',
          name: 'Hilda Details',
          path: '/accounts/:advisorFirmId/hildaDetails/:hildaAccountId/:hildaStatusId/:hildaSubmitted',
          render: (props, { userData }) => {
            const user = userData.user;
            const { hildaStatusId, hildaSubmitted }: any = props.match.params;

            if (hildaStatusId === HildaStatus.Pending) {
              if (user.role.name === 'HildaUser')
                return <Route component={HildaPendingClient} />;
              if (hildaSubmitted === 'true')
                return <Route component={HildaAwaitingClientApproval} />;
              return <Route component={HildaPendingAdvisor} />;
            }
            return <Route component={HildaDetails} />;
          },
          permission: 'hildaDetails',
        },
        {
          id: 'a',
          componentName: 'AdvisorDashboard',
          name: 'Accounts',
          path: '/accounts/:advisorFirmId/advisorDashboard',
          render: () => {
            return <Route component={AdvisorDashboard} />;
          },
          permission: 'advisorDashboard',
        },
        {
          id: 'a',
          componentName: 'AdvisorPreFill',
          name: 'Advisor PreFill',
          path: '/accounts/:advisorFirmId/advisorPreFill',
          render: () => {
            return <Route component={AdvisorPreFill} />;
          },
          permission: 'advisorPreFill',
        },
        {
          id: 'a',
          componentName: 'PlaidConnect',
          name: 'Plaid View',
          path: '/plaidView/:oauth_state_id?',
          render: () => {
            return <Route component={PlaidView} />;
          },
          permission: 'plaid',
        },
      ];
    }
  }
};

export const getAllRoutes = () => {
  const routesOverview = getRoutes(MenuIndex.Overview);
  const routesAccounts = getRoutes(MenuIndex.Accounts);
  const routesTopNav = getRoutes(MenuIndex.TopNav);
  const defaultNav = getRoutes(MenuIndex.Default);
  const allRoutes = routesOverview.concat(
    routesAccounts,
    routesTopNav,
    defaultNav
  );
  const componentNames = allRoutes.map((route) => route.componentName);
  const uniqueComponentNames = componentNames.filter(
    (v, i, a) => a.indexOf(v) === i
  );
  return uniqueComponentNames.map(
    (compName) =>
      allRoutes.filter((route) => route.componentName === compName)[0]
  );
};

export const getPath = (
  routeName: RouteName,
  routeProps: {
    advisorFirmId: string | number;
    hildaAccountId?: string | number;
    hildaStatusId?: string | number;
    hildaSubmitted?: string | number;
  }
) => {
  const { advisorFirmId, hildaAccountId, hildaStatusId, hildaSubmitted } =
    routeProps;
  const route = getAllRoutes().find((r) => r.name === routeName);
  if (!route) return '';
  return generatePath(route.path, {
    advisorFirmId,
    hildaAccountId,
    hildaStatusId,
    hildaSubmitted,
  });
};

const getHilda = (userData: IUserData) => {
  const accountList = userData.accountsSearch.accountList;
  if (accountList[0].accounts.length !== 0) return accountList[0].accounts[0];
  else if (accountList[1].accounts.length !== 0)
    return accountList[1].accounts[0];
  else if (accountList[2].accounts.length !== 0)
    return accountList[2].accounts[0];
  else return null;
};
